<template>
  <!-- 新增/编辑停车场对话框 -->
  <div class="box-card">
    <el-form :model="batchAddForm" :rules="batchAddFormRules" ref="batchAddFormRef" label-width="120px"
      class="demo-ruleForm">
      <el-form-item label="停车场名称：" prop="parking_name">
        <el-input v-model="batchAddForm.parking_name" maxlength="20" placeholder="请输入停车场名称"
          @input="(e) => (batchAddForm.parking_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item label="总车位数：" prop="berth_num" v-if="inOutPark === 'outPark'">
        <el-input v-model="batchAddForm.berth_num" maxlength="9" placeholder="请输入总车位数"
          @input="(e) => (batchAddForm.berth_num = validNumber(e))"></el-input>
      </el-form-item>
      <el-form-item label="所在位置：" prop="position">
        <div class="button_box">
          <el-input class="mapInput"  v-model="batchAddForm.position" disabled
            v-show="batchAddForm.position" @input="(e) => (batchAddForm.position = validSpace(e))"></el-input>
          <lbButton class="mapButton" icon="zhongxuan" :fill="true" @click="openMapDialog">{{ batchAddForm.position ? "重新选择" : "地图标注" }}
          </lbButton>
        </div>
      </el-form-item>
      <el-form-item label="默认车牌：">
        <el-autocomplete :trigger-on-focus="false" popper-class="my-select" v-model="batchAddForm.default_plate_number"
          :fetch-suggestions="querySearch" placeholder="例：湘A" @select="handleSelect" style="width:100%;">
          <i class="el-icon-circle-close el-input__icon" slot="suffix" v-if="batchAddForm.default_plate_number"
            @click="handleIconClick">
          </i>
          <template slot-scope="{ item }">
            <div class="name">{{ item.name }}</div>
          </template>
        </el-autocomplete>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm()">保 存</lbButton>
    </span>
    <!-- 地图标注对话框 -->
    <lebo-dialog append-to-body class="mapDialog" width="50%" title="选择地图坐标" :isShow="addressInnerVisible"
      @close="closeAddress" footerSlot>
      <div class="searchMap">
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-select popper-class="my-select" v-model="addressInfo.province_code" filterable clearable
              placeholder="请选择省" @change="provinceChange">
              <el-option v-for="item in provinceSelectList" :key="item.specific_code" :label="item.specific_name"
                :value="item.specific_code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select popper-class="my-select" v-model="addressInfo.city_code" filterable clearable placeholder="请选择市"
              @change="cityChange">
              <el-option v-for="item in citySelectList" :key="item.specific_code" :label="item.specific_name"
                :value="item.specific_code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select popper-class="my-select" v-model="addressInfo.district_code" filterable clearable
              placeholder="请选择区" @change="districtChange">
              <el-option v-for="item in districtSelectList" :key="item.specific_code" :label="item.specific_name"
                :value="item.specific_code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input id="inputkeyword" v-model="keyword" placeholder="请输入关键字"
              @input="(e) => (keyword = validSpace(e))"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div id="amapall" class="all-amap"></div>
    </lebo-dialog>
  </div>
</template>
<script>
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import addressjs from '@/utils/city'
import { addParking, editParking, getParkDetails } from '@/api/outParking.js'
import { getParkingDetails, getParkingNameIsReuse, updateParkingInfo, addParkingInfo } from '@/api/curbParkapi'
import { mapState, mapMutations } from 'vuex'
import { getProvinceList } from '@/api/administrative'
import plateNumberHeadList from '@/utils/plate_number_head'
export default {
  props: ['id', 'inOutPark'],
  data () {
    return {
      plateNumberHeadList: plateNumberHeadList,
      // 新增/编辑停车场表单
      batchAddForm: {
        _id: '',
        parking_name: '',
        berth_num: null,
        position: '',
        longitude: '',
        latitude: '',
        province: '',
        province_code: '',
        city: '',
        city_code: '',
        district: '',
        district_code: '',
        default_plate_number: ''
        // tcpservice_device_no: '' // tcp序列号
      },
      // 新增/编辑停车场表单验证
      batchAddFormRules: {
        parking_name: [
          { required: true, message: '请输入停车场名称', trigger: 'blur' }
        ],
        berth_num: [
          { required: true, message: '请输入总车位数', trigger: 'blur' },
          {
            pattern: /^\+?[1-9][0-9]*$/,
            message: '总车位数必须为不能为0的正整数',
            trigger: 'blur'
          }
        ],
        position: [
          { required: true, message: '请标注所在位置', trigger: 'change' }
        ]
        // tcpservice_device_no: [
        //   { required: true, message: '请输入TCP服务序列号', trigger: 'change' }
        // ]
      },
      addressInnerVisible: false,
      keyword: '',
      map: {},
      infoWindow: {},
      marker: {},
      geocoder: {},
      autocomplete: null,
      addressInfo: {
        longitude: '',
        latitude: '',
        address: '',
        province: '',
        province_code: '',
        city: '',
        city_code: '',
        district: '',
        district_code: ''
      },
      provinceSelectList: [], // 省份下拉列
      citySelectList: [], // 市下拉列
      districtSelectList: [] // 区下拉列
    }
  },
  created () {
    console.log(this.id)
    console.log('inOutPark', this.inOutPark)
    if (this.id) {
      this.requestParkingDetails()
    }
  },
  mounted () {
  },
  computed: {},
  watch: {
    'addressInnerVisible' (newValue, oldValue) {
      var that = this
      if (newValue) {
        // that.initMap()
      }
    }
  },
  methods: {
    // 获取路外车场信息
    async requestParkingDetails () {
      let res = ''
      if (this.inOutPark === 'outPark') {
        res = await getParkDetails({
          parking_id: this.id
        })
      } else if (this.inOutPark === 'curPark') {
        // 获取路内车场信息
        res = await getParkingDetails({
          parking_id: this.id
        })
      }
      if (res.Code === 200) {
        this.batchAddForm = {
          _id: res.Data._id,
          parking_name: res.Data.parking_name,
          berth_num: res.Data.berth_num,
          position: res.Data.position,
          longitude: res.Data.longitude,
          latitude: res.Data.latitude,
          province: res.Data.province,
          province_code: res.Data.province_code,
          city: res.Data.city,
          city_code: res.Data.city_code,
          district: res.Data.district,
          district_code: res.Data.district_code,
          default_plate_number: res.Data.default_plate_number
          // tcpservice_device_no: res.Data.tcpservice_device_no
        }
        // setTimeout(() => { this.initMap(); }, 100);
      }
    },
    initMap () {
      const that = this
      lazyAMapApiLoaderInstance.load().then(() => {
        this.map = new AMap.Map('amapall', {
          zoom: 12
        })
        this.map.clearMap()
        if (that.batchAddForm.longitude && that.batchAddForm.latitude) {
          that.map.setCenter([that.batchAddForm.longitude, that.batchAddForm.latitude])
        }
        this.infoWindow = new AMap.InfoWindow({
          offset: new AMap.Pixel(0, -30)
        })
        this.marker = new AMap.Marker()
        that.map.plugin(['AMap.Geocoder'], () => {
          that.geocoder = new AMap.Geocoder({
            radius: 1000 // 范围，默认：500
          })
          if (that.batchAddForm.position) {
            that.geocoder.getLocation(that.batchAddForm.position, function (status, result) {
              if (status === 'complete' && result.geocodes.length) {
                var lnglat = result.geocodes[0].location
                that.showInfoWindow(lnglat, that.batchAddForm.position)
              }
            })
          }
        })
        this.map.on('click', (e) => {
          that.sureLnglatMarker(e.lnglat)
        })
        AMap.event.addListener(that.marker, 'click', function () {
          // console.log("=-=-=-=-=markerclick");
          that.infoWindow.open(that.map, that.marker.getPosition())
        })
        AMap.plugin('AMap.Geolocation', function () {
          var geolocation = new AMap.Geolocation({
            enableHighAccuracy: true, // 是否使用高精度定位，默认:true
            timeout: 10000, // 超过10秒后停止定位，默认：5s
            buttonPosition: 'RB', // 定位按钮的停靠位置
            buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
            zoomToAccuracy: true, // 定位成功后是否自动调整地图视野到定位点
            showCircle: false // 是否显示定位精度圈
          })
          that.map.addControl(geolocation)
          geolocation.getCurrentPosition(function (status, result) {
            console.log('status', status)
            if (status === 'complete') {
              // onComplete(result)
            } else {
              // onError(result)
            }
          })
        })
        // 输入提示
        that.map.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], () => {
          var autoOptions = {
            input: 'inputkeyword'
          }
          that.autocomplete = new AMap.Autocomplete(autoOptions)
          var placeSearch = new AMap.PlaceSearch({
            map: that.map
          }) // 构造地点查询类
          AMap.event.addListener(that.autocomplete, 'select', select)// 注册监听，当选中某条记录时会触发
          function select (e) {
            placeSearch.setCity(e.poi.adcode)
            // placeSearch.search(e.poi.name);  // 关键字查询查询
            // console.log('=-=-=-=select-==-=-=', JSON.stringify(e));
            if (e.poi.location === '') {
              that.geocoder.getLocation(`${e.poi.district}${e.poi.name}${e.poi.address}`, function (status, result) {
                if (status === 'complete' && result.geocodes.length) {
                  var lnglat = result.geocodes[0].location
                  that.sureLnglatMarker(lnglat)
                }
              })
            } else {
              that.sureLnglatMarker(e.poi.location)
            }
          }
        })
      })
    },
    sureLnglatMarker (lnglat) {
      var that = this
      var specialPoint = ['北京市', '上海市', '天津市', '重庆市']
      var oneCountry_twoSystems = ['台湾省', '澳门特别行政区', '香港特别行政区']
      that.geocoder.getAddress(lnglat, function (status, result) {
        // console.log('=-=-=-=getAddress=', JSON.stringify(result))
        if (status === 'complete' && result.regeocode) {
          const { province, city, district, adcode } = result.regeocode.addressComponent
          that.batchAddForm.province = province
          that.batchAddForm.city = city
          that.batchAddForm.district = district
          that.batchAddForm.province_code = addressjs.province_list.find(item => item.name === province).code
          //
          that.addressInfo.province = province
          that.addressInfo.city = city
          that.addressInfo.district = district
          that.addressInfo.province_code = addressjs.province_list.find(
            (item) => item.name === province
          ).code

          // 获取市区下拉列
          that.fngetProvinceList({
            specific_level: 2,
            specific_code: that.addressInfo.province_code
          }, 2)
          if (city === '') {
            if (specialPoint.indexOf(province) > -1) { // 直辖市
              that.batchAddForm.city = province
              that.addressInfo.city = province
            } else if (adcode.startsWith('4690') || adcode.startsWith('6590')) { // 海南省 青海省
              that.batchAddForm.city = district
              that.addressInfo.city = district
            } else if (oneCountry_twoSystems.indexOf(province) > -1) { // 台湾 香港 澳门
              that.batchAddForm.province = province
              that.batchAddForm.city = province
              that.batchAddForm.district = province
              //
              that.addressInfo.province = province
              that.addressInfo.city = province
              that.addressInfo.district = province
            }
          }
          if (that.batchAddForm.city === '') {
            // 有部分地区的province === "中华人民共和国"；
            return
          }
          console.log('-=========that.batchAddForm.city=', that.batchAddForm.city)
          that.batchAddForm.city_code = addressjs.city_list.find(item =>
            item.name === that.batchAddForm.city
          ).code
          that.addressInfo.city_code = addressjs.city_list.find(
            (item) => item.name === that.addressInfo.city
          ).code
          // 获取区级下拉列
          that.fngetProvinceList({
            specific_level: 3,
            specific_code: that.addressInfo.city_code
          }, 3)
          that.batchAddForm.district_code = addressjs.district_list.find(item => item.name === that.batchAddForm.district).code
          that.addressInfo.district_code = addressjs.district_list.find(
            (item) => item.name === that.addressInfo.district
          ).code
          // console.log("-=-=-=batchAddForm-=-", JSON.stringify(that.batchAddForm));
          var address = result.regeocode.formattedAddress
          that.addressInfo.address = address
          that.addressInfo.longitude = lnglat.lng
          that.addressInfo.latitude = lnglat.lat
          that.showInfoWindow(lnglat, address)
        }
      })
    },
    showInfoWindow (lnglat, address) {
      var infoDiv = document.createElement('div')
      infoDiv.className = 'infodiv'
      var addressspan = document.createElement('span')
      addressspan.className = 'addressspan'
      addressspan.innerText = address
      infoDiv.appendChild(addressspan)
      var confirmbtn = document.createElement('input')
      confirmbtn.className = 'input-btn'
      confirmbtn.type = 'button'
      confirmbtn.value = '确定'
      var that = this
      confirmbtn.onclick = function () {
        that.batchAddForm.position = address
        that.batchAddForm.longitude = lnglat.lng
        that.batchAddForm.latitude = lnglat.lat
        that.addressInnerVisible = false
      }
      infoDiv.appendChild(confirmbtn)
      this.infoWindow.setContent(infoDiv)
      this.map.add(this.marker)
      this.map.setCenter(lnglat)
      this.marker.setPosition(lnglat)
      this.infoWindow.open(this.map, this.marker.getPosition())
    },
    getAdress () {
      const that = this
      this.geocoder.getLocation(that.keyword, function (status, result) {
        if (status === 'complete' && result.geocodes.length) {
          var lnglat = result.geocodes[0].location
          that.sureLnglatMarker(lnglat)
        }
      })
    },
    // 关闭地图对话框
    closeAddress () {
      this.keyword = ''
      this.addressInnerVisible = false
      this.keyword = ''
      this.addressInfo = {
        longitude: '',
        latitude: '',
        address: '',
        province: '',
        province_code: '',
        city: '',
        city_code: '',
        district: '',
        district_code: ''
      }
      this.provinceSelectList = []
      this.citySelectList = []
      this.districtSelectList = []
    },
    querySearch (queryString, cb) {
      var restaurants = this.plateNumberHeadList
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      // console.log('results----', results);
      if (results.length <= 0) this.batchAddForm.default_plate_number = ''
      cb(results)
    },
    createFilter (queryString) {
      return (restaurant) => {
        // console.log(restaurant);
        return (restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) !== -1)
      }
    },
    handleSelect (item) {
      // console.log('item----', item);
      this.batchAddForm.default_plate_number = item.value
    },
    handleIconClick (ev) {
      // console.log(ev);
      this.batchAddForm.default_plate_number = ''
    },
    submitAddForm () {
      this.$refs.batchAddFormRef.validate((valid) => {
        if (valid) {
          if (this.inOutPark === 'curPark') {
            // 路内停车新增与编辑
            this.fngetParkingNameIsReuse()
          } else if (this.inOutPark === 'outPark') {
            // 路外停车新增与编辑
            if (this.id) {
              this.editRequest()
            } else {
              this.addNew()
            }
          }
        } else {
          return false
        }
      })
    },
    // 修改路外
    async editRequest () {
      //   console.log('this.batchAddForm', this.batchAddForm)
      const res = await editParking(this.batchAddForm)
      if (res.Code === 200) {
        var that = this
        setTimeout(() => {
          that.$store.dispatch('requestPorkingList')
        }, 300)
        // this.$emit('close')
        this.$emit('closeDialog', false)
      }
    },
    // 新增路外
    async addNew () {
      const { _id, ...params } = this.batchAddForm
      const res = await addParking(params)
      if (res.Code === 200) {
        var that = this
        setTimeout(() => {
          that.$store.dispatch('requestPorkingList')
        }, 300)
        // this.$emit('close')
        this.$emit('closeDialog', false)
      }
    },
    // 编辑路内停车场请求
    async fnUpdateParkingInfo (obj) {
      const res = await updateParkingInfo(obj)
      // console.log(res);
      var that = this
      setTimeout(() => {
        that.$store.dispatch('requestPorkingList')
      }, 300)
      this.$emit('closeDialog', false)
    },
    // 新增路内停车场请求
    async fnAddParkingInfo (obj) {
      const res = await addParkingInfo(obj)
      // console.log(res);
      var that = this
      setTimeout(() => {
        that.$store.dispatch('requestPorkingList')
      }, 300)
      this.$emit('closeDialog', false)
    },
    // 判断路内停车场名称是否重复
    async fngetParkingNameIsReuse () {
      // console.log(this.batchAddForm._id);
      const res = await getParkingNameIsReuse({
        parking_id: this.batchAddForm._id ? this.batchAddForm._id : '',
        parking_name: this.batchAddForm.parking_name
      })
      // console.log(res);
      if (res && res.Code === 200) {
        var obj = {
          parking_name: this.batchAddForm.parking_name,
          berth_num: this.batchAddForm.berth_num,
          position: this.batchAddForm.position,
          longitude: this.batchAddForm.longitude,
          latitude: this.batchAddForm.latitude,
          province: this.batchAddForm.province,
          province_code: this.batchAddForm.province_code,
          city: this.batchAddForm.city,
          city_code: this.batchAddForm.city_code,
          district: this.batchAddForm.district,
          district_code: this.batchAddForm.district_code,
          default_plate_number: this.batchAddForm.default_plate_number
        }
        // 编辑停车场
        if (this.id) {
          obj._id = this.batchAddForm._id
          this.fnUpdateParkingInfo(obj)
        } else {
          // 新增路内停车场
          // console.log(obj);
          obj.berth_num = 0
          this.fnAddParkingInfo(obj)
        }
      }
    },
    // 省下拉列发生改变触发
    provinceChange (val) {
      console.log('provinceChange', val)
      // 或没有数据，则清空省市区
      if (!val) {
        this.addressInfo.province = ''
        this.addressInfo.city = ''
        this.addressInfo.city_code = ''
        this.addressInfo.district = ''
        this.addressInfo.district_code = ''
        return
      };
      // 清空市区
      this.addressInfo.city = ''
      this.addressInfo.city_code = ''
      this.addressInfo.district = ''
      this.addressInfo.district_code = ''
      // 筛选出符合条件的数据
      var item = this.provinceSelectList.find(item => item.specific_code === val)
      this.addressInfo.province = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
      // 获取市下拉列表
      this.fngetProvinceList({
        specific_level: 2,
        specific_code: val
      }, 2)
    },
    // 市下拉列发生改变触发
    cityChange (val) {
      if (!val) {
        this.addressInfo.city = ''
        this.addressInfo.district = ''
        this.addressInfo.district_code = ''
        return
      }
      // 清空区级数据
      this.addressInfo.district = ''
      this.addressInfo.district_code = ''
      // 筛选出符合条件的数据
      var item = this.citySelectList.find(item => item.specific_code === val)
      this.addressInfo.city = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
      this.autocomplete.setCity(val)
      // 获取区级下拉列表
      this.fngetProvinceList({
        specific_level: 3,
        specific_code: val
      }, 3)
    },
    // 区下拉列发生改变触发
    districtChange (val) {
      // 或没有数据，则清空省市区
      if (!val) {
        this.addressInfo.district = ''
        return
      };
      // 筛选出符合条件的数据
      var item = this.districtSelectList.find(item => item.specific_code === val)
      this.addressInfo.district = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
    },
    // 获取省市区列表
    async fngetProvinceList (params, type) {
      // console.log('获取省市区列表');
      // type: 1 省；2 市；3 区
      const res = await getProvinceList(params)
      console.log('fngetProvinceList', res)
      if (type === 1) {
        this.provinceSelectList = res && res.Code === 200 ? res.Data : []
      } else if (type === 2) {
        this.citySelectList = res && res.Code === 200 ? res.Data : []
      } else if (type === 3) {
        this.districtSelectList = res && res.Code === 200 ? res.Data : []
      }
    },
    // 打开地图对话框
    openMapDialog () {
      this.addressInnerVisible = true
      this.position = this.batchAddForm.position
      this.fngetProvinceList({
        specific_level: 1
      }, 1)
      if (this.batchAddForm.province_code) {
        // 获取市下拉列表
        this.fngetProvinceList({
          specific_level: 2,
          specific_code: this.batchAddForm.province_code
        }, 2)
      }
      if (this.batchAddForm.city_code) {
        // 获取区下拉列表
        this.fngetProvinceList({
          specific_level: 3,
          specific_code: this.batchAddForm.city_code
        }, 3)
      }
      this.addressInfo.longitude = this.batchAddForm.longitude
      this.addressInfo.latitude = this.batchAddForm.latitude
      this.addressInfo.address = this.batchAddForm.addres
      this.addressInfo.province = this.batchAddForm.province
      this.addressInfo.province_code = this.batchAddForm.province_code
      this.addressInfo.city = this.batchAddForm.city
      this.addressInfo.city_code = this.batchAddForm.city_code
      this.addressInfo.district = this.batchAddForm.district
      this.addressInfo.district_code = this.batchAddForm.district_code
      this.initMap()
    }
  }
}
</script>
<style scoped lang="less">
.button_box {
  display: flex;
  justify-content: space-between;
  .mapInput {
    width: 74%;
    // margin-right: 15px;
  }
  .mapButton{
    width: 24%;
  }
  .lb-button {
    margin-right: 0;
  }
}

.button_box .el-button {
  width: auto;
}

.el-dialog__body {
  padding: 20px;
  position: relative;
}

.all-amap {
  width: 100%;
  height: 500px;

  /deep/.amap-maps {
    .amap-drags {
      .amap-overlays {
        .amap-info {
          .amap-info-contentContainer {
            .amap-info-content {
              background: blanchedalmond;

              .infodiv {
                display: flex;
                width: 150px;
                flex-direction: column;
                align-items: flex-start;

                .input-btn {
                  background: #409eff;
                  margin-top: 10px;
                  color: #ffffff;
                  font-weight: 400;
                  border: none;
                  border-radius: 2px;
                }
              }
            }

            .amap-info-sharp {
              bottom: 0;
              left: 50%;
              margin-left: -8px;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-top: 8px solid blanchedalmond;
            }

            .amap-info-close {
              position: absolute;
              top: 5px;
              right: 5px !important;
            }
          }
        }
      }
    }
  }
}

.el-button {
  width: auto !important;
}

.searchMap {
  display: flex;
  justify-content: start;
  margin: 15px 0 15px 15px;
  position: absolute;
  top: 50px;
  left: 10px;
  z-index: 99;

  .el-select.el-select--small {
    width: 150px;
  }
}

.searchMap .el-input {
  width: 250px;
  margin-right: 15px;
}

.mapDialog {
  /deep/ .el-dialog__body {
    padding: 0;
  }
}

.btnTempate {
  margin-bottom: 8px;
}

.infodiv {
  display: flex;
  height: 70px;
  width: 150px;
  padding: 20px;
  background-color: #ff00ff;
  flex-direction: column;
  align-items: flex-start;
}

.addressspan {
  font-size: 16px;
  text-align: left;
}

.box-card {
  margin-top: 0 !important;
  position: relative;
  padding-bottom: 30px;

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }
}
</style>
